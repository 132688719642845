<template>
  <div class="logo" >
  <svg height="100" viewBox="0 0 340 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.1351 31.9653C11.4597 31.9653 6.8875 34.4093 2.90897 38.6594C2.89634 38.672 2.88371 38.6846 2.87108 38.6973C2.87108 38.6973 2.86477 38.7036 2.85845 38.7099C2.03749 39.594 1.27967 40.535 0.559748 41.5138V41.4885C-3.99977 47.6584 -6.39321 55.3818 -4.73864 60.4213C-3.8419 63.1621 -1.9221 64.7029 0.566062 64.9998V64.9745C0.869189 65.0124 1.18495 65.0313 1.50702 65.0313C4.99928 65.0313 9.39461 62.8274 13.6321 58.2931C18.9683 52.5905 21.848 45.5681 21.8417 40.1813C21.8417 37.6868 21.2165 35.5397 19.9282 34.043C18.7221 32.6284 17.0549 31.9653 15.1414 31.9653M3.51523 58.4131C1.67121 58.4131 0.174525 57.3774 -0.444357 55.4513C-0.671701 54.7693 -0.772744 54.0178 -0.772744 53.2157C-0.772744 49.7172 1.21021 45.3345 4.20358 42.1264C6.62227 39.5372 9.41356 38.0279 11.7312 38.0215H11.7438C12.9437 38.0215 14.0362 38.451 14.8067 39.3603C15.634 40.3329 16.0192 41.6843 16.0192 43.2315C16.0192 46.5533 14.2573 50.8034 11.0239 54.2767C8.44734 57.0301 5.75078 58.4131 3.54049 58.4131H3.50891" fill="white"/>
    <path d="M55.1288 -6.14038C50.6008 -6.14038 45.0119 -3.79115 40.3008 -0.11575H40.364C39.101 0.86941 37.8758 1.91772 36.7707 3.07339C36.7138 3.13654 36.657 3.19338 36.6002 3.25653C32.8364 7.27926 30.7271 11.7377 30.7271 15.3121C30.7271 17.4719 31.4976 19.3096 33.1332 20.5347C34.5162 21.5641 36.3539 22.063 38.4568 22.063C43.9573 22.063 51.2639 18.6654 56.9033 12.6345C57.238 12.2745 57.5601 11.9209 57.8632 11.5609C57.8758 11.5483 57.8822 11.5357 57.8948 11.523C57.8948 11.523 57.9074 11.5104 57.9074 11.5041C57.9074 11.5041 57.9137 11.4915 57.92 11.4915C61.5134 7.26032 63.0921 3.10496 62.8016 -0.109435H62.8143C62.5617 -2.93861 60.8566 -5.04155 57.7937 -5.81831C56.9601 -6.03302 56.0634 -6.13407 55.1224 -6.13407M41.1534 16.5057C39.8525 16.5057 38.6905 16.2025 37.8001 15.5394C36.7391 14.7501 36.2465 13.5502 36.2465 12.1924C36.2465 9.94425 37.5538 7.22243 39.8462 4.77216C43.0163 1.38725 47.5696 -0.949347 51.1629 -0.955662C51.7439 -0.955662 52.3059 -0.89251 52.8301 -0.759893C54.933 -0.242053 56.0823 1.35567 56.0697 3.38283C56.0697 5.52366 54.8635 8.15707 52.4259 10.7652C48.9905 14.4343 44.5699 16.5057 41.1597 16.512" fill="white"/>
    <path d="M35.8045 60.9834C30.2851 60.9834 24.5699 63.661 19.9219 68.631C19.903 68.65 19.8904 68.6689 19.8714 68.6815C19.8714 68.6815 19.8714 68.6815 19.8651 68.6879C19.8525 68.7068 19.8398 68.7194 19.8209 68.7384C19.8209 68.7384 19.8209 68.7384 19.8209 68.7447C19.8083 68.7636 19.7893 68.7763 19.7767 68.7952C19.202 69.4141 18.6526 70.0709 18.1158 70.7529C18.1158 70.7529 18.1158 70.7529 18.1158 70.7592C18.1032 70.7718 18.0905 70.7908 18.0779 70.8034C18.0779 70.8097 18.0653 70.816 18.059 70.8287C18.0527 70.8413 18.0463 70.8476 18.0337 70.8603C18.0274 70.8729 18.0211 70.8792 18.0148 70.8918C18.0148 70.8981 18.0021 70.9045 17.9958 70.9108C12.0849 78.59 8.85783 89.7614 9.88088 100.143H9.87457C10.1272 102.67 10.6261 105.145 11.4028 107.513C14.6235 117.34 21.6523 123.118 29.5525 123.118C34.7246 123.118 40.2693 120.642 45.3529 115.199C49.4136 110.854 52.2491 105.625 53.9163 100.143H53.9099C56.998 89.9951 56.1076 78.9815 51.6112 71.0939C51.6112 71.0939 51.6112 71.0876 51.6112 71.0813C51.6049 71.0687 51.5986 71.056 51.5923 71.0434C51.5923 71.0434 51.586 71.0308 51.5797 71.0245C51.5733 71.0118 51.567 71.0055 51.5607 70.9929C51.5544 70.9739 51.5418 70.9676 51.5355 70.9487C51.5355 70.9487 51.5355 70.9487 51.5355 70.936C51.5355 70.9234 51.5228 70.9108 51.5165 70.9045C50.7966 69.6604 49.9819 68.4984 49.0789 67.4311C49.0789 67.4311 49.0662 67.4122 49.0599 67.4059C49.0599 67.3996 49.0473 67.3933 49.041 67.3869C49.041 67.3869 49.0284 67.3743 49.0284 67.368C49.0157 67.3554 49.0031 67.3427 48.9968 67.3301C45.3087 63.0232 40.6482 60.9897 35.8361 60.9897M29.8177 110.715C24.9361 110.715 20.6229 107.141 18.6779 101.173C17.9642 99.0005 17.6359 96.6828 17.6359 94.3462C17.6359 88.0942 20.004 81.7033 23.8563 77.5732C26.6854 74.5483 30.1777 72.9 33.5815 72.9H33.5879C36.5497 72.9 39.4294 74.1568 41.6839 76.7902C44.4057 79.973 45.8013 84.5325 45.8076 89.3383C45.8076 95.1356 43.7742 101.305 39.5051 105.871C36.417 109.174 33.0195 110.702 29.8304 110.702" fill="white"/>
    <path d="M96.6697 6.57178C86.035 6.57178 75.0214 11.1629 68.4474 18.1853C64.3236 22.5996 62.1449 27.7653 62.1512 32.6975C62.1512 37.6864 64.3804 42.4354 69.0663 45.934C73.7079 49.3946 79.9599 51.1439 86.5781 51.1439C96.4297 51.1439 107.083 47.2728 114.422 39.4231C118.943 34.592 120.989 29.5462 120.989 24.8983C120.989 16.9223 114.927 10.1209 104.93 7.57588C102.259 6.89385 99.4736 6.57178 96.6634 6.57178M87.1402 41.1408C83.1237 41.1408 79.3157 40.0798 76.4739 37.9643C73.6005 35.8234 72.2112 32.8806 72.2175 29.8051C72.2175 26.7676 73.5563 23.6037 76.0697 20.9197C80.0735 16.6381 86.7423 13.8721 93.1964 13.8657C94.9015 13.8657 96.5939 14.0615 98.2232 14.472C104.305 16.0129 108.062 20.1998 108.062 25.1319C108.062 27.999 106.793 31.0871 104.052 34.0236C99.5873 38.7979 93.1269 41.1408 87.1465 41.1408" fill="white"/>
    <path d="M35.0877 0.00630295H0V35.0877C5.6172 28.9677 17.0692 17.484 34.0988 0.888696C34.4086 0.586161 34.7449 0.289929 35.0811 0" fill="#231F20"/>
    <path d="M339.474 -0.115967H68.4727C68.5611 0.806042 68.5674 1.76594 68.4032 2.80794C67.9106 5.8834 66.1234 9.32515 64.0584 13.31C61.3681 18.4947 58.3179 24.3741 57.737 30.5061C56.9539 38.7789 59.2652 44.2794 65.4667 48.902C70.7082 52.8111 77.9643 54.9583 85.9214 54.9583H86.1361L339.474 40.8124V-0.115967ZM62.1512 32.6974C62.1512 27.7653 64.3236 22.5995 68.4474 18.1853C75.0151 11.1628 86.0287 6.57175 96.6697 6.57175C99.4799 6.57175 102.265 6.89382 104.936 7.57586C114.933 10.1209 120.989 16.9222 120.996 24.8982C120.996 29.5462 118.949 34.5857 114.428 39.423C107.09 47.2727 96.4297 51.1439 86.5845 51.1439C79.9662 51.1439 73.7142 49.4009 69.0726 45.9339C64.3868 42.4417 62.1575 37.6927 62.1575 32.6974" fill="#231F20"/>
    <path d="M65.8325 0.0125965H62.3597C62.6507 3.21849 61.0629 7.36915 57.4572 11.5828C57.4572 11.5828 57.4509 11.5954 57.4445 11.5954C57.4445 11.5954 57.4319 11.608 57.4319 11.6143C57.4192 11.6269 57.4129 11.6395 57.4002 11.6521C57.0966 12.0111 56.774 12.3638 56.4387 12.7228C50.7835 18.7441 43.4708 22.1263 37.9611 22.1263C35.8546 22.1263 34.0138 21.6351 32.6284 20.6021C30.9837 19.3802 30.212 17.5474 30.2183 15.3933C30.2183 11.8284 32.3311 7.38175 36.1013 3.36965C36.1582 3.30667 36.2152 3.24998 36.2721 3.187C37.3665 2.04699 38.5557 0.976255 39.8082 0H37.0755C36.0507 0.762109 35.1018 1.56201 34.2731 2.406C17.0037 19.9345 5.72484 31.744 0.75277 37.5134C0.487086 37.822 0.246706 38.1306 0 38.4455V41.5003C0.714815 40.5366 1.48656 39.6045 2.30259 38.7353C2.30259 38.7353 2.30891 38.7227 2.31524 38.7227C2.32789 38.7101 2.34054 38.6975 2.35319 38.6849C6.33212 34.4398 10.9183 32.0086 14.5999 32.0086C16.5166 32.0086 18.1867 32.6699 19.3949 34.0807C20.679 35.5798 21.3053 37.7149 21.3116 40.2028C21.3179 45.5754 18.4334 52.5792 13.0881 58.2667C8.84346 62.7889 4.42173 65.006 0.923566 65.006C0.607276 65.006 0.303638 64.9871 0 64.9493V68.4701C0.455457 68.5457 0.955195 68.5898 1.50554 68.5898C2.18872 68.5898 2.95415 68.5268 3.80813 68.3945C8.10967 67.7395 13.3664 64.6722 18.4523 61.7119C21.1977 60.1121 23.7976 58.6005 26.1382 57.5235C29.7059 55.8922 33.28 55.0923 36.6959 55.0923C42.8572 55.0923 48.5315 57.6872 52.8647 62.7134C61.2527 70.6935 64.1435 85.8286 60.3734 100H339.474V57.1708L85.7082 57.1078H85.4804C77.0481 57.1078 69.3244 54.8151 63.7007 50.6393C56.7993 45.5187 54.2247 39.4533 55.0913 30.3458C55.7113 23.8017 58.8678 17.7426 61.6575 12.3953C63.6438 8.58475 65.3518 5.29697 65.7946 2.58235C65.9401 1.65648 65.9337 0.806197 65.8325 0.0125965Z" fill="#231F20"/>
    <path d="M2.90898 38.6589C2.90898 38.6589 2.88372 38.6842 2.87109 38.6968C2.88372 38.6842 2.89635 38.6716 2.90898 38.6589ZM2.85846 38.7095C2.04381 39.5873 1.27336 40.5156 0.559753 41.4818V41.5071C1.27968 40.5345 2.03749 39.5873 2.85846 38.7031M21.8354 40.1683C21.8417 45.5551 18.962 52.5775 13.6257 58.28C9.3883 62.808 4.98666 65.0183 1.50071 65.0183C1.17864 65.0183 0.869195 64.9993 0.559753 64.9614V64.9867C0.856564 65.0246 1.16601 65.0435 1.48176 65.0435C4.97403 65.0435 9.3883 62.8206 13.6257 58.2863C18.962 52.5838 21.8417 45.5614 21.8354 40.1746" fill="#231F20"/>
    <path d="M62.8269 -0.115967H62.8142C63.1047 3.09843 61.526 7.2601 57.9326 11.4849C61.526 7.25379 63.1111 3.09843 62.8269 -0.115967ZM40.364 -0.115967H40.3008C39.0504 0.862878 37.8632 1.93013 36.7707 3.07949C37.8758 1.92382 39.0946 0.869193 40.364 -0.115967ZM57.92 11.5039C57.92 11.5039 57.9074 11.5165 57.9074 11.5228C57.9074 11.5228 57.92 11.5102 57.92 11.5039ZM56.9033 12.6406C51.2639 18.6779 43.9573 22.0691 38.4568 22.0691C36.3539 22.0691 34.5162 21.5765 33.1332 20.5408C31.4912 19.3157 30.7208 17.478 30.7271 15.3182C30.7271 17.478 31.4975 19.322 33.1332 20.5408C34.5162 21.5702 36.3539 22.0691 38.4568 22.0691C43.9573 22.0691 51.2639 18.6715 56.9033 12.6406Z" fill="#231F20"/>
    <path d="M53.3037 62.707L53.3795 62.7891C53.3795 62.7891 53.3542 62.7639 53.3479 62.7512C53.3353 62.7386 53.3163 62.726 53.3037 62.707Z" fill="#231F20"/>
    <path d="M37.202 57.0176C34.0469 57.0176 30.7382 57.7709 27.372 59.2965C25.1 60.322 22.5209 61.8159 19.7882 63.3985C14.4636 66.4813 8.95972 69.6718 4.19187 70.3934C3.2191 70.539 2.34233 70.6086 1.54235 70.6086C0.99197 70.6086 0.479985 70.577 0 70.5137V100H9.42691C8.39014 89.5931 11.6668 78.3949 17.6571 70.6973C17.6571 70.6909 17.6699 70.6846 17.6763 70.6783C17.6826 70.6719 17.6891 70.6593 17.6955 70.6466C17.7019 70.634 17.7146 70.6276 17.721 70.615C17.7274 70.6086 17.7339 70.6023 17.7402 70.5896C17.753 70.5707 17.7658 70.558 17.7786 70.5453C17.7786 70.5453 17.7786 70.5453 17.7786 70.539C18.3162 69.8553 18.8794 69.197 19.4618 68.5766C19.4746 68.5576 19.4938 68.5386 19.5066 68.526C19.5066 68.526 19.5066 68.526 19.5066 68.5197C19.5194 68.5007 19.5386 68.488 19.5514 68.469C19.5514 68.469 19.5514 68.469 19.5578 68.4627C19.5706 68.4437 19.5898 68.431 19.609 68.412C24.3192 63.4301 30.1111 60.7461 35.7109 60.7461C40.5875 60.7461 45.3106 62.7844 49.0481 67.1017C49.0609 67.1143 49.0737 67.127 49.0801 67.1397C49.0801 67.146 49.0929 67.1523 49.0929 67.1586C49.0993 67.165 49.1057 67.1713 49.1121 67.1776C49.1185 67.184 49.1249 67.1903 49.1313 67.203C50.0464 68.2728 50.872 69.4375 51.6016 70.6846C51.608 70.6973 51.6144 70.7099 51.6208 70.7226C51.6208 70.7226 51.6208 70.7289 51.6208 70.7352C51.6272 70.7479 51.64 70.7669 51.6464 70.7796C51.6528 70.7922 51.6592 70.7986 51.6656 70.8112C51.6656 70.8112 51.6656 70.8239 51.6784 70.8302C51.6848 70.8429 51.6912 70.8555 51.6976 70.8682C51.6976 70.8682 51.6976 70.8745 51.6976 70.8808C56.2607 78.7873 57.163 89.8336 54.0335 100H58.7822C62.6477 86.3203 59.9725 71.6468 51.9024 64.0632L51.864 64.0252L51.8256 63.9872C47.8449 59.3788 42.7635 57.0239 37.1828 57.0239" fill="#231F20"/>
    <path d="M48.9652 67.3235C48.9652 67.3235 48.9905 67.3487 48.9968 67.3614C48.9842 67.3487 48.9779 67.3361 48.9652 67.3235ZM49.0158 67.3803C49.0158 67.3803 49.0284 67.3929 49.0347 67.3993C49.0347 67.3929 49.0221 67.3866 49.0158 67.3803ZM19.8714 68.6876C19.8714 68.6876 19.8715 68.6876 19.8651 68.6939C19.8651 68.6939 19.8651 68.6939 19.8714 68.6876ZM19.8209 68.7444C19.8209 68.7444 19.8209 68.7444 19.8209 68.7507C19.8209 68.7507 19.8209 68.7507 19.8209 68.7444ZM18.1095 70.7589C18.1095 70.7589 18.1095 70.7589 18.1095 70.7652C18.1095 70.7652 18.1095 70.7652 18.1095 70.7589ZM18.0716 70.8094C18.0716 70.8094 18.059 70.8221 18.0527 70.8347C18.0527 70.8284 18.0653 70.8221 18.0716 70.8094ZM18.0274 70.86C18.0274 70.86 18.0148 70.8789 18.0085 70.8915C18.0148 70.8789 18.0211 70.8726 18.0274 70.86ZM17.9895 70.9105C12.0786 78.5897 8.84524 89.7611 9.86829 100.143H9.8746C8.85155 89.7611 12.0786 78.5897 17.9895 70.9105ZM51.5229 70.9357C51.5229 70.9357 51.5229 70.9421 51.5229 70.9484C51.5229 70.9484 51.5229 70.9484 51.5229 70.9357ZM51.5544 70.9926C51.5544 70.9926 51.5671 71.0115 51.5734 71.0242C51.5734 71.0115 51.5608 71.0052 51.5544 70.9926ZM51.5797 71.0431C51.5797 71.0431 51.5923 71.0684 51.5986 71.081C51.5923 71.0684 51.586 71.0557 51.5797 71.0431ZM51.605 71.0936C56.1013 78.9812 56.9918 89.9948 53.9037 100.143H53.91C56.9981 90.0011 56.1076 78.9812 51.605 71.0936Z" fill="#231F20"/>
    <path d="M14.611 39.5432C17.0486 42.385 15.514 49.0854 10.8345 54.0933C6.15502 59.0949 1.1345 59.4043 -0.185364 55.3626C-1.33472 51.8577 0.780851 46.1867 4.40573 42.3093C8.03062 38.4255 12.4954 37.0614 14.611 39.5369" fill="#00AEEF"/>
    <path d="M11.7501 38.0276H11.7375C9.41353 38.0276 6.62856 39.5432 4.20355 42.1324C1.21018 45.3405 -0.766451 49.7232 -0.772766 53.2218C-0.772766 54.0175 -0.671724 54.769 -0.44438 55.4573C0.174503 57.3835 1.67119 58.4191 3.5152 58.4191H3.54678C5.75707 58.4191 8.45363 57.0361 11.0302 54.2827C14.2636 50.8157 16.0255 46.5656 16.0255 43.2376C16.0255 41.6967 15.6466 40.3389 14.813 39.3664C14.0362 38.457 12.95 38.0276 11.7501 38.0276ZM3.54678 58.1476C1.79118 58.1476 0.414478 57.2129 -0.185459 55.3689C-1.33481 51.864 0.780756 46.193 4.40564 42.3156C6.79276 39.7642 9.53984 38.2991 11.7438 38.2991C12.8932 38.2991 13.891 38.697 14.6172 39.5432C17.0548 42.3913 15.5203 49.0854 10.8408 54.0933C8.29576 56.8151 5.64972 58.1476 3.5531 58.1476" fill="#00AEEF"/>
    <path d="M11.7376 38.2993C9.53358 38.2993 6.7865 39.7644 4.39939 42.3157C0.774503 46.1932 -1.34106 51.8642 -0.191712 55.3691C0.408225 57.2131 1.78492 58.1477 3.54053 58.1477C5.63715 58.1477 8.28319 56.8152 10.8282 54.0934C15.5077 49.0855 17.0423 42.3852 14.6046 39.5434L14.4025 39.7202C15.1162 40.5538 15.4824 41.7789 15.4824 43.2377C15.4824 46.3763 13.7837 50.538 10.6324 53.9166C8.12531 56.6005 5.53611 57.8825 3.55316 57.8825H3.54053C1.87965 57.8699 0.6482 57.0363 0.0672085 55.287C-0.134876 54.6681 -0.235917 53.9734 -0.235917 53.2219C-0.235917 49.9254 1.68388 45.6059 4.59515 42.4988C6.93807 39.9854 9.63462 38.5645 11.7186 38.5645H11.7312C12.8174 38.5645 13.7268 38.9308 14.4025 39.7139L14.6046 39.537C13.8784 38.6908 12.8806 38.293 11.7312 38.293" fill="#00AEEF"/>
    <path d="M52.7669 -0.507323C56.8844 0.534673 56.9096 5.56783 52.2301 10.5757C47.5506 15.5836 40.9703 17.5603 37.9643 15.3184C35.3561 13.3733 36.4234 8.82643 40.0419 4.94895C43.6731 1.06514 49.1862 -1.41039 52.7606 -0.507323" fill="#EC008C"/>
    <path d="M51.1693 -0.968295C47.576 -0.96198 43.0291 1.37462 39.8526 4.75953C37.5602 7.21611 36.2592 9.93161 36.2529 12.1798C36.2529 13.5376 36.7455 14.7374 37.8065 15.5268C38.6969 16.1899 39.8589 16.493 41.1598 16.493C44.57 16.493 48.9969 14.4154 52.426 10.7463C54.8636 8.13812 56.0635 5.50471 56.0698 3.36388C56.0824 1.33673 54.9331 -0.261 52.8301 -0.778841C52.306 -0.911458 51.7503 -0.974609 51.1629 -0.974609M52.7607 -0.519921C56.8781 0.522075 56.9034 5.55523 52.2239 10.5631C48.839 14.188 44.4563 16.2215 41.1535 16.2215C39.8904 16.2215 38.7916 15.9247 37.958 15.3058C35.3499 13.3607 36.4171 8.81384 40.0357 4.93635C43.1554 1.59565 47.677 -0.703059 51.1566 -0.703059C51.7187 -0.703059 52.2555 -0.639908 52.7544 -0.513605" fill="#EC008C"/>
    <path d="M51.1692 -0.696566C47.6895 -0.696566 43.1742 1.60214 40.0482 4.94284C36.4233 8.82033 35.3561 13.3672 37.9705 15.3123C38.8041 15.9312 39.903 16.228 41.166 16.228C44.4688 16.228 48.8515 14.1882 52.2364 10.5696C56.9159 5.56172 56.8907 0.534884 52.7732 -0.513427L52.7037 -0.254507C54.5983 0.238073 55.5203 1.53899 55.5329 3.36406C55.5329 5.3028 54.4151 7.83517 52.0343 10.3802C48.6999 13.9545 44.3678 15.9564 41.1723 15.9564H41.1597C39.9409 15.9564 38.8989 15.6659 38.1284 15.0976C37.219 14.4155 36.7959 13.4177 36.7896 12.18C36.7896 10.1402 38.0148 7.50678 40.244 5.12598C43.3131 1.8358 47.7906 -0.43133 51.1502 -0.43133H51.1692C51.7123 -0.43133 52.2301 -0.374494 52.7037 -0.254507L52.7732 -0.513427C52.2743 -0.639729 51.7375 -0.702881 51.1755 -0.702881" fill="#EC008C"/>
    <path d="M33.5437 73.4365C30.323 73.4365 26.957 75.0027 24.2163 77.9392C20.4714 81.9367 18.1411 88.2202 18.1411 94.3396C18.1411 96.6257 18.4632 98.8865 19.1578 100.996C21.065 106.812 25.1951 110.172 29.7925 110.172C32.8048 110.172 36.0571 108.732 39.0758 105.511C43.2374 101.059 45.233 95.0153 45.233 89.3443C45.233 84.6396 43.8563 80.2 41.2418 77.1435C39.0694 74.6175 36.3603 73.4365 33.55 73.4365" fill="#231F20"/>
    <path d="M33.55 72.8994H33.5437C30.1461 72.8994 26.6475 74.5413 23.8184 77.5726C19.9661 81.6964 17.6043 88.0873 17.598 94.3456C17.598 96.6822 17.9263 98.9998 18.64 101.172C20.5913 107.14 24.9046 110.714 29.7798 110.714C32.969 110.714 36.3665 109.186 39.4546 105.883C43.7236 101.311 45.7571 95.1476 45.7571 89.3503C45.7571 84.5445 44.3552 79.9787 41.6333 76.8022L41.4313 76.979C47.3169 83.8562 46.9696 97.4526 39.2525 105.706C36.2023 108.965 32.8806 110.455 29.7735 110.455C25.0372 110.455 20.8187 106.988 18.8862 101.096C16.3034 93.2215 18.7852 83.3509 24.0015 77.7684C26.7928 74.7876 30.2156 73.1836 33.531 73.1836C36.417 73.1836 39.2146 74.4024 41.4313 76.9853L41.6333 76.8085C39.3788 74.1751 36.4991 72.912 33.5373 72.912" fill="#231F20"/>
    <path d="M33.5437 73.1714C30.2345 73.1714 26.8054 74.7754 24.0141 77.7561C18.7978 83.3324 16.316 93.2092 18.8989 101.084C20.8313 106.976 25.0498 110.443 29.7862 110.443C32.8869 110.443 36.215 108.959 39.2652 105.694C46.9822 97.4404 47.3233 83.8439 41.4439 76.9667C39.2336 74.3839 36.436 73.165 33.5437 73.165M29.7862 110.165C25.1887 110.165 21.065 106.806 19.1515 100.989C18.4568 98.8802 18.1347 96.6194 18.1347 94.3333C18.1347 88.214 20.465 81.9304 24.2099 77.933C26.957 74.9964 30.3166 73.4303 33.5373 73.4303C36.3476 73.4303 39.0631 74.6112 41.2292 77.1372C43.8436 80.1875 45.2203 84.6333 45.2203 89.3381C45.2203 95.0091 43.2247 101.053 39.0631 105.505C36.0508 108.726 32.7985 110.165 29.7798 110.165" fill="#231F20"/>
    <path d="M98.1601 14.7312C107.652 17.1499 111.22 25.9595 103.856 33.8408C96.4866 41.7157 83.5658 42.9156 76.6381 37.7498C71.0493 33.5818 71.2892 26.4331 76.2656 21.1032C81.2482 15.7732 90.4935 12.7861 98.1538 14.7375" fill="#FFF200"/>
    <path d="M93.2027 13.866C86.7423 13.866 80.0799 16.6383 76.0698 20.92C73.5563 23.6102 72.2175 26.7678 72.2175 29.8053C72.2175 32.8808 73.6005 35.8237 76.4739 37.9645C79.3157 40.0864 83.1238 41.141 87.1402 41.141C93.1206 41.141 99.581 38.7981 104.046 34.0239C106.787 31.0873 108.056 27.9992 108.056 25.1322C108.056 20.1937 104.305 16.0131 98.2169 14.4722L98.1474 14.7311C107.639 17.1498 111.207 25.9594 103.844 33.8407C99.4357 38.5518 93.0448 40.8695 87.1339 40.8695C83.1616 40.8695 79.4105 39.8211 76.6255 37.7435C71.0366 33.5755 71.2766 26.4268 76.2529 21.0968C80.1935 16.8846 86.8055 14.1312 93.1837 14.1312C94.8699 14.1312 96.5371 14.3207 98.1411 14.7311L98.2106 14.4722C96.5876 14.0617 94.8951 13.866 93.1837 13.866" fill="#FFF200"/>
    <path d="M93.2027 14.1313C86.8181 14.1313 80.2125 16.8847 76.2718 21.0969C71.2955 26.4269 71.0492 33.5756 76.6444 37.7436C79.4294 39.8213 83.1806 40.8696 87.1465 40.8696C93.0574 40.8696 99.4483 38.5456 103.856 33.8409C111.22 25.9659 107.652 17.15 98.1601 14.7313C96.556 14.3208 94.8888 14.1313 93.2027 14.1313ZM87.1465 40.598C83.2248 40.598 79.5304 39.5624 76.8086 37.5289C74.0552 35.4765 72.7669 32.7168 72.7669 29.7992C72.7669 26.9195 74.0363 23.8882 76.4739 21.2801C80.3514 17.131 86.8939 14.3966 93.2027 14.3966H93.2153C94.8825 14.3966 96.5307 14.586 98.1095 14.9902C104.027 16.5058 107.544 20.4907 107.544 25.1323C107.544 27.8352 106.351 30.797 103.679 33.6577C99.3347 38.3057 93.0069 40.6044 87.1654 40.6044L87.1465 40.598Z" fill="#FFF200"/>
    <path d="M73.7584 64.3743H71.1755V76.4551H73.7584V64.3743Z" fill="white"/>
    <path d="M80.0988 64.5322L78.0211 67.1214H76.2529L77.7117 64.5322H80.0988Z" fill="white"/>
    <path d="M83.6668 68.1255H86.2497V76.4488H83.6668V68.1255ZM84.9361 67.0329C84.071 67.0329 83.5089 66.4204 83.5089 65.6626C83.5089 64.9047 84.0836 64.2795 84.9677 64.2795C85.8518 64.2795 86.4012 64.8732 86.4202 65.6626C86.4202 66.4267 85.8581 67.0329 84.9551 67.0329H84.9424H84.9361Z" fill="white"/>
    <path d="M91.8576 70.7907C91.8576 69.7424 91.826 68.8709 91.7944 68.1257H93.9858L94.0868 69.2625H94.1373C94.491 68.7257 95.2488 67.9363 96.6571 67.9363C97.7433 67.9363 98.5769 68.5046 98.9305 69.3698H98.9621C99.2842 68.9278 99.6441 68.5867 100.061 68.3531C100.516 68.0815 101.027 67.9363 101.633 67.9363C103.237 67.9363 104.444 69.0667 104.444 71.5422V76.4491H101.924V71.9338C101.924 70.7212 101.539 70.0266 100.699 70.0266C100.08 70.0266 99.682 70.4371 99.4863 70.9296L99.4042 71.5738V76.4428H96.8907V71.7885C96.8907 70.7402 96.5118 70.0266 95.6782 70.0266C95.0088 70.0266 94.6236 70.5444 94.472 70.9486C94.3899 71.1444 94.371 71.3843 94.371 71.618V76.4491H91.8513V70.7844L91.8576 70.7907Z" fill="white"/>
    <path d="M112.022 72.8745L112.072 73.4239C112.243 74.1123 112.836 74.6364 113.588 74.6364C114.718 74.6364 115.375 73.7081 115.375 72.2872C115.375 70.9673 114.782 69.9632 113.626 69.9632C112.893 69.9632 112.249 70.4937 112.079 71.2705L112.028 71.7315V72.8682L112.022 72.8745ZM109.439 70.9168C109.439 69.8306 109.395 68.9149 109.369 68.1255H111.618L111.731 69.2938H111.769C112.382 68.4223 113.335 67.9424 114.542 67.9424C116.367 67.9424 117.996 69.5275 117.996 72.1798C117.996 75.2048 116.07 76.6383 114.219 76.6383C113.215 76.6383 112.439 76.2341 112.06 75.691H112.022V79.8211H109.439V70.9231V70.9168Z" fill="white"/>
    <path d="M122.859 70.8665C122.859 69.6414 122.846 68.852 122.795 68.1257H125.012L125.113 69.6729H125.176C125.599 68.4415 126.61 67.9363 127.424 67.9363C127.664 67.9363 127.784 67.9489 127.968 67.9931V70.4244C127.746 70.3865 127.538 70.3487 127.248 70.3487C126.294 70.3487 125.65 70.8475 125.486 71.6622L125.429 72.2369V76.4491H122.846L122.859 70.8665Z" fill="white"/>
    <path d="M132.508 68.1255H135.097V76.4488H132.508V68.1255ZM133.79 67.0329C132.919 67.0329 132.363 66.4204 132.363 65.6626C132.363 64.9047 132.944 64.2795 133.828 64.2795C134.712 64.2795 135.255 64.8732 135.274 65.6626C135.274 66.4267 134.712 67.0329 133.809 67.0329H133.79Z" fill="white"/>
    <path d="M140.408 70.7907C140.408 69.7424 140.377 68.8709 140.339 68.1257H142.536L142.638 69.2625H142.688C143.042 68.7257 143.787 67.9363 145.208 67.9363C146.294 67.9363 147.128 68.5046 147.488 69.3698H147.519C147.841 68.9278 148.195 68.5867 148.605 68.3531C149.066 68.0815 149.578 67.9363 150.19 67.9363C151.788 67.9363 152.994 69.0667 152.994 71.5422V76.4491H150.481V71.9338C150.481 70.7212 150.089 70.0266 149.262 70.0266C148.65 70.0266 148.233 70.4371 148.056 70.9296L147.967 71.5738V76.4428H145.454V71.7885C145.454 70.7402 145.069 70.0266 144.235 70.0266C143.578 70.0266 143.181 70.5444 143.035 70.9486C142.947 71.1444 142.934 71.3843 142.934 71.618V76.4491H140.415V70.7844L140.408 70.7907Z" fill="white"/>
    <path d="M163.023 71.3211C163.023 70.7148 162.751 69.6349 161.577 69.6349C160.471 69.6349 160.036 70.639 159.966 71.3211H163.023ZM159.973 73.0893C160.061 74.1818 161.109 74.687 162.322 74.687C163.212 74.687 163.926 74.5733 164.62 74.3271L164.961 76.0827C164.109 76.4426 163.067 76.6068 161.949 76.6068C159.126 76.6068 157.51 74.9712 157.51 72.3567C157.51 70.2538 158.836 67.9109 161.722 67.9109C164.406 67.9109 165.429 70.0075 165.429 72.0662C165.429 72.5083 165.372 72.9062 165.34 73.1019L159.979 73.0893H159.973Z" fill="white"/>
    <path d="M170.323 70.8665C170.323 69.6414 170.31 68.852 170.254 68.1257H172.47L172.578 69.6729H172.641C173.064 68.4415 174.074 67.9363 174.883 67.9363C175.129 67.9363 175.242 67.9489 175.432 67.9931V70.4244C175.211 70.3865 175.003 70.3487 174.712 70.3487C173.765 70.3487 173.108 70.8475 172.944 71.6622L172.887 72.2369V76.4491H170.31L170.323 70.8665Z" fill="white"/>
    <path d="M180.661 68.1255H183.25V76.4488H180.661V68.1255ZM181.93 67.0329C181.065 67.0329 180.503 66.4204 180.503 65.6626C180.503 64.9047 181.078 64.2795 181.968 64.2795C182.859 64.2795 183.395 64.8732 183.408 65.6626C183.408 66.4267 182.846 67.0329 181.943 67.0329H181.924H181.93Z" fill="white"/>
    <path d="M193.102 71.3211C193.102 70.7148 192.83 69.6349 191.656 69.6349C190.557 69.6349 190.108 70.639 190.039 71.3211H193.102ZM190.051 73.0893C190.134 74.1818 191.195 74.687 192.407 74.687C193.291 74.687 193.998 74.5733 194.693 74.3271L195.034 76.0827C194.188 76.4426 193.152 76.6068 192.022 76.6068C189.193 76.6068 187.576 74.9712 187.576 72.3567C187.576 70.2538 188.908 67.9109 191.782 67.9109C194.466 67.9109 195.495 70.0075 195.495 72.0662C195.495 72.5083 195.445 72.9062 195.407 73.1019L190.045 73.0893H190.051Z" fill="white"/>
    <path d="M73.8973 79.9602H71.2639V92.2621H73.8973V79.9602Z" fill="white"/>
    <path d="M85.1256 87.0459C85.1256 86.4207 84.8478 85.3281 83.6542 85.3281C82.5301 85.3281 82.0817 86.3512 82.0123 87.0459H85.1319H85.1256ZM82.0249 88.8457C82.1133 89.9571 83.1869 90.475 84.412 90.475C85.3151 90.475 86.0413 90.355 86.7549 90.1087L87.1022 91.8959C86.2371 92.2558 85.1825 92.4327 84.0331 92.4327C81.1597 92.4327 79.5115 90.7718 79.5115 88.1005C79.5115 85.9533 80.8629 83.5789 83.7931 83.5789C86.5276 83.5789 87.5696 85.707 87.5696 87.8037C87.5696 88.2584 87.5191 88.6499 87.4812 88.8583L82.0249 88.8457Z" fill="white"/>
    <path d="M93.8026 89.9759C94.2889 90.2664 95.293 90.5948 96.0761 90.5948C96.8591 90.5948 97.2002 90.3359 97.2002 89.9001C97.2002 89.4644 96.9412 89.2433 95.9687 88.9086C94.2005 88.3403 93.5248 87.3678 93.5437 86.3826C93.5437 84.7912 94.8951 83.5913 96.9918 83.5913C97.9832 83.5913 98.8484 83.8313 99.3662 84.0902L98.9179 85.8963C98.539 85.7069 97.7938 85.429 97.0802 85.429C96.4423 85.429 96.0761 85.6879 96.0761 86.1047C96.0761 86.5215 96.4045 86.7299 97.4464 87.0899C99.0568 87.6267 99.7199 88.4603 99.7325 89.6917C99.7325 91.2831 98.5011 92.4451 96.0824 92.4451C94.9709 92.4451 93.9858 92.1862 93.3416 91.8389L93.8089 89.9696L93.8026 89.9759Z" fill="white"/>
    <path d="M122.549 87.3425L122.499 86.9257C122.36 86.2374 121.785 85.6627 120.97 85.6627C119.777 85.6627 119.152 86.6668 119.152 88.0372C119.152 89.4076 119.846 90.3738 120.958 90.3738C121.703 90.3738 122.309 89.8749 122.48 89.1802C122.53 89.0097 122.549 88.7887 122.549 88.6119V87.3425ZM122.549 91.1884H122.518C121.981 92.0031 121.135 92.4515 119.985 92.4515C118.147 92.4515 116.487 90.8285 116.487 88.1382C116.487 85.107 118.413 83.5977 120.231 83.5977C121.324 83.5977 122.12 83.9955 122.606 84.7786H122.638L122.707 83.7934H125.239C125.22 84.6081 125.189 85.4543 125.189 86.3384V95.6911H122.555V91.1884H122.549Z" fill="white"/>
    <path d="M139.594 89.5399C139.594 90.6451 139.625 91.5482 139.663 92.2618H137.377L137.257 91.0682H137.206C136.878 91.586 136.082 92.4512 134.56 92.4512C132.824 92.4512 131.58 91.3777 131.58 88.7632V83.7932H134.213V88.3464C134.213 89.5778 134.611 90.323 135.546 90.323C136.272 90.323 136.708 89.8178 136.865 89.401C136.935 89.2494 136.967 89.0537 136.967 88.8263V83.7869H139.6V89.5399H139.594Z" fill="white"/>
    <path d="M151.586 88.2583C150.203 88.2583 149.129 88.5867 149.129 89.5908C149.129 90.2665 149.578 90.5949 150.165 90.5949C150.809 90.5949 151.359 90.1655 151.536 89.6224L151.592 89.1677V88.252L151.586 88.2583ZM154.131 90.216C154.131 91.0117 154.163 91.7948 154.27 92.2621H151.902L151.744 91.4158H151.693C151.138 92.0916 150.272 92.4515 149.268 92.4515C147.551 92.4515 146.534 91.2074 146.534 89.856C146.534 87.6583 148.511 86.6164 151.51 86.6164V86.5153C151.51 86.0669 151.264 85.4228 149.969 85.4228C149.104 85.4228 148.182 85.7133 147.633 86.048L147.147 84.3682C147.734 84.0398 148.896 83.6104 150.437 83.6104C153.26 83.6104 154.144 85.2712 154.144 87.2668V90.2286L154.131 90.216Z" fill="white"/>
    <path d="M164.406 81.4821V83.7872H166.294V85.7259H164.406V88.8077C164.406 89.8307 164.665 90.2981 165.441 90.2981C165.801 90.2981 165.978 90.2791 166.218 90.2286L166.237 92.2242C165.909 92.3442 165.265 92.4452 164.538 92.4452C163.686 92.4452 162.972 92.1547 162.543 91.719C162.057 91.2138 161.81 90.4054 161.81 89.2055V85.7196H160.686V83.7808H161.81V82.1831L164.393 81.4758L164.406 81.4821Z" fill="white"/>
    <path d="M173.55 86.5784C173.55 85.3344 173.531 84.5197 173.481 83.7935H175.729L175.836 85.3722H175.906C176.335 84.1218 177.358 83.604 178.192 83.604C178.438 83.604 178.558 83.623 178.747 83.6545V86.1364C178.52 86.0985 178.318 86.0669 178.021 86.0669C177.049 86.0669 176.392 86.5658 176.221 87.3994C176.184 87.5762 176.165 87.7657 176.165 87.9867V92.2684H173.531L173.55 86.5847V86.5784Z" fill="white"/>
    <path d="M190.493 87.0459C190.493 86.4207 190.216 85.3281 189.022 85.3281C187.892 85.3281 187.443 86.3512 187.374 87.0459H190.493ZM187.393 88.8457C187.475 89.9571 188.548 90.475 189.78 90.475C190.683 90.475 191.409 90.355 192.123 90.1087L192.47 91.8959C191.605 92.2558 190.55 92.4327 189.401 92.4327C186.528 92.4327 184.879 90.7718 184.879 88.1005C184.879 85.9533 186.231 83.5789 189.161 83.5789C191.895 83.5789 192.937 85.707 192.937 87.8037C192.937 88.2584 192.887 88.6499 192.849 88.8583L187.393 88.8457Z" fill="white"/>
    <path d="M214.163 88.6245C214.163 88.8329 214.182 89.0034 214.213 89.1739C214.384 89.8875 214.99 90.4243 215.754 90.4243C216.897 90.4243 217.573 89.4707 217.573 88.0309C217.573 86.6794 216.967 85.6564 215.792 85.6564C215.047 85.6564 214.39 86.2121 214.213 86.9889L214.163 87.4562V88.6182V88.6245ZM211.529 86.6289C211.529 85.5238 211.491 84.5891 211.46 83.7934H213.746L213.866 84.968H213.904C214.523 84.0839 215.495 83.5977 216.727 83.5977C218.577 83.5977 220.244 85.208 220.244 87.9109C220.244 90.9927 218.286 92.4451 216.398 92.4451C215.375 92.4451 214.579 92.0347 214.2 91.4726H214.163V95.6848H211.529V86.6226V86.6289Z" fill="white"/>
    <path d="M230.38 90.5632C231.416 90.5632 232.06 89.5402 232.06 88.0182C232.06 86.7742 231.573 85.4859 230.38 85.4859C229.186 85.4859 228.643 86.7678 228.643 88.0372C228.643 89.477 229.249 90.5696 230.361 90.5696H230.38V90.5632ZM230.329 92.4515C227.854 92.4515 225.947 90.8285 225.947 88.0877C225.947 85.3469 227.746 83.5977 230.487 83.5977C233.064 83.5977 234.8 85.3785 234.8 87.9488C234.8 91.0306 232.603 92.4451 230.348 92.4451H230.329V92.4515Z" fill="white"/>
    <path d="M241.987 83.7936H244.62V92.2621H241.987V83.7936ZM243.282 82.6821C242.398 82.6821 241.823 82.0632 241.823 81.2801C241.823 80.497 242.41 79.8782 243.313 79.8782C244.216 79.8782 244.766 80.4844 244.785 81.2801C244.785 82.0632 244.21 82.6821 243.294 82.6821H243.275H243.282Z" fill="white"/>
    <path d="M252.451 86.4899C252.451 85.4353 252.413 84.5512 252.382 83.7871H254.668L254.788 84.9617H254.838C255.186 84.406 256.032 83.5913 257.453 83.5913C259.183 83.5913 260.484 84.7533 260.484 87.2478V92.2494H257.851V87.5572C257.851 86.4647 257.472 85.7195 256.518 85.7195C255.792 85.7195 255.356 86.2247 255.167 86.711C255.097 86.8815 255.078 87.1278 255.078 87.3678V92.2557H252.445V86.4899H252.451Z" fill="white"/>
    <path d="M270.121 81.4821V83.7872H272.009V85.7259H270.121V88.8077C270.121 89.8307 270.38 90.2981 271.163 90.2981C271.529 90.2981 271.7 90.2791 271.94 90.2286L271.959 92.2242C271.63 92.3442 270.986 92.4452 270.26 92.4452C269.414 92.4452 268.7 92.1547 268.264 91.719C267.778 91.2138 267.538 90.4054 267.538 89.2055V85.7196H266.408V83.7808H267.538V82.1831L270.121 81.4758V81.4821Z" fill="white"/>
    <path d="M277.844 89.9759C278.331 90.2664 279.335 90.5948 280.118 90.5948C280.901 90.5948 281.242 90.3359 281.242 89.9001C281.242 89.4644 280.983 89.2433 280.01 88.9086C278.242 88.3403 277.566 87.3678 277.585 86.3826C277.585 84.7912 278.937 83.5913 281.033 83.5913C282.019 83.5913 282.884 83.8313 283.408 84.0902L282.96 85.8963C282.581 85.7069 281.835 85.429 281.122 85.429C280.478 85.429 280.118 85.6879 280.118 86.1047C280.118 86.5215 280.446 86.7299 281.488 87.0899C283.098 87.6267 283.755 88.4603 283.774 89.6917C283.774 91.2831 282.543 92.4451 280.118 92.4451C279.006 92.4451 278.021 92.1862 277.377 91.8389L277.844 89.9696V89.9759Z" fill="white"/>
    <path d="M300.756 85.8583H302.745V92.2619H300.756V85.8583ZM301.734 85.0247C301.065 85.0247 300.636 84.5511 300.636 83.9638C300.636 83.3765 301.078 82.9028 301.76 82.9028C302.442 82.9028 302.859 83.3575 302.871 83.9638C302.871 84.5511 302.435 85.0247 301.747 85.0247H301.734Z" fill="white"/>
    <path d="M308.921 87.8982C308.921 87.1025 308.89 86.4331 308.864 85.8584H310.588L310.683 86.7488H310.721C310.986 86.332 311.624 85.7195 312.698 85.7195C314.005 85.7195 314.984 86.5973 314.984 88.4792V92.262H312.994V88.7128C312.994 87.8919 312.71 87.3235 311.99 87.3235C311.441 87.3235 311.112 87.7024 310.967 88.0687C310.917 88.2013 310.904 88.3845 310.904 88.5676V92.2556H308.915V87.8982H308.921Z" fill="white"/>
    <path d="M325.454 92.1041C325.101 92.2746 324.431 92.4072 323.673 92.4072C321.602 92.4072 320.282 91.1379 320.282 89.117C320.282 87.2477 321.564 85.7195 323.951 85.7195C324.475 85.7195 325.05 85.8079 325.467 85.9658L325.151 87.4435C324.917 87.3425 324.564 87.2477 324.04 87.2477C322.991 87.2477 322.316 87.9929 322.322 89.0412C322.322 90.2158 323.105 90.8474 324.078 90.8474C324.551 90.8474 324.917 90.7716 325.221 90.639L325.461 92.1041H325.454Z" fill="white"/>
    <path d="M330.437 92.4072C329.774 92.4072 329.287 91.8957 329.287 91.201C329.287 90.5063 329.774 89.9822 330.468 89.9822C331.163 89.9822 331.618 90.4684 331.63 91.201C331.63 91.8957 331.157 92.4072 330.456 92.4072H330.437Z" fill="white"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Logo',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
